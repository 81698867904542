<template>
	<div class="section-5">
		<div class="container-section">
			<img id="square1Sect5" class="square-1" src="/build/images/square-1-sect-5.svg">
			<img id="square2Sect5" class="square-2" src="/build/images/square-2-sect-5.svg">

			<div class="svg-animation-1"><svg-1-section-5 /></div>

			<div class="content">
				<div class="content-left">
					<div class="center-on-div text-sect5">
						<div class="fixed-text">Funcționează remarcabil cu</div>
						<div id="numberChanged" class="text-1 strong">{{ startNumber }} colegi</div>
					</div>
				</div>
				<div class="content-right">
					<div class="text-info">
						Sistemul de management prin obiective funcționează remarcabil, de la nivelul de startup până la cel de multinațională cu mii de angajați și zeci de locații.
					</div>
				</div>
			</div>

			<div class="svg-animation-2"><svg-2-section-5 /></div>
		</div>
	</div>
</template>

<script>
	import Svg1Section5 from '../../assets/landing/Svg1Section5'
	import Svg2Section5 from '../../assets/landing/Svg2Section5'
	import { gsap } from "gsap";
	import { ScrollTrigger } from "gsap/ScrollTrigger";

	export default {
		data() {
			return {
				startNumber: 6,
			};
		},
		components:{
			Svg1Section5,
			Svg2Section5
		},
		mounted() {
			gsap.from("#square1Sect5", {
				scrollTrigger: {
					trigger: '#square1Sect5',
					start: "top center"
				},
				opacity: 0
			})

			gsap.from("#square2Sect5", {
				scrollTrigger: {
					trigger: '#square2Sect5',
					start: "top center"
				},
				opacity: 0
			})

			gsap.set(".path-hidden-5-3", {visibility:"visible"})

			var startCount = 6, num = {var:startCount};
			gsap.timeline({
				scrollTrigger: {
					trigger: '.section-5',
					pin: true,  
					start: "top top",
					end: "+=100% top",
					scrub: true
				}
			})
			.to(num, {var: 20, duration: 1, ease:"none", onUpdate:() => this.changeNumber(num)})
			.to({}, {duration:2})

			.to(num, {var: 100, duration: 1, ease:"none", onUpdate:() => this.changeNumber(num)})
			.add(this.animateUsersCount(), "-=1")
			.to({}, {duration:2})

			.to(num, {var: 1000, duration: 1, ease:"none", onUpdate:() => this.changeNumber(num)})
			.add(this.animateUsersCount2(), "-=1")
			.to({}, {duration:2})
		},
		methods: {
			animateUsersCount(){
				var tUsers = gsap.timeline();
					tUsers.from('.line-3-sect-5', {drawSVG: 0})
							.to('.user-5-sect-5', {opacity: 1})
				return tUsers;
			},
			animateUsersCount2(){
				var tUsers2 = gsap.timeline();
					tUsers2.to('.user-3-sect-5', {opacity: 1})
							.from('.line-2-sect-5', {drawSVG: 0})
							.to('.user-4-sect-5', {opacity: 1})
				return tUsers2;
			},
			changeNumber(num){
				this.startNumber = (num.var).toFixed()
			}
		}
	}
</script>